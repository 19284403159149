import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface ISEOProps {
	title?: string;
	description?: string;
	keywords?: string[];
}

export const SEO: React.FC<ISEOProps> = props => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					keywords
				}
			}
		}
	`);

	const { title, description, meta = [] } = site;
	const siteTitle = props.title || title;
	const siteDescription = props.description || description;
	const metaData = [
		{
			name: 'description',
			content: siteDescription,
		},
		{
			name: 'og:title',
			content: siteTitle,
		},
		{
			name: 'twitter:card',
			content: 'summary',
		},
		{
			name: 'twitter:title',
			content: title,
		},
		{
			name: 'twitter:description',
			content: siteDescription,
		},
		{
			property: 'og:description',
			content: siteDescription,
		},
		{
			property: 'og:type',
			content: 'website',
		},
		{
			name: 'og:description',
			content: siteDescription,
		},
		{
			name: 'keywords',
			content: site.siteMetadata.keywords.join(','),
		},
		{
			name: 'viewport',
			content: 'width=device-width, initial-scale=1, maximum-scale=1',
		},
	].concat(meta);

	return (
		<Helmet
			htmlAttributes={{ lang: 'en' }}
			title={siteTitle}
			// titleTemplate={`%s | ${siteTitle}`}
			meta={metaData}
		/>
	);
};
