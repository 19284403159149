import React from 'react';
import HandshakeIcon from '../../../assets/ic_handshake.svg';
import PlusIcon from '../../../assets/ic_plus.svg';
import SchoolIcon from '../../../assets/ic_school.svg';
import OfficeIcon from '../../../assets/ic_office.svg';
import SpeakIcon from '../../../assets/ic_speak2.svg';
import DoorsIcon from '../../../assets/ic_doors.svg';
import ContactIcon from '../../../assets/ic_contact.svg';
import DiscountIcon from '../../../assets/ic_discount.svg';
import { CardWithIcon } from '../../../types';

export const possibilities: CardWithIcon[] = [
	{
		icon: <HandshakeIcon />,
		title: 'Employment assistance from our specialists',
	},
	{
		icon: <PlusIcon />,
		title: 'Tracking current trends in the marketplace',
	},
	{
		icon: <SchoolIcon />,
		title: 'Access to regular private educational events',
	},
	{
		icon: <OfficeIcon />,
		title: 'Networking with HR and recruiters from IT companies',
	},
	{
		icon: <SpeakIcon />,
		title: 'Networking with experienced colleagues and opportunity to share experiences',
	},
	{
		icon: <DoorsIcon />,
		title: 'Extended opportunities to participate in general festivals and conferences',
	},
	{
		icon: <ContactIcon />,
		title: 'A wide range of new professional acquaintances and contacts',
	},
	{
		icon: <DiscountIcon />,
		title: 'Discounts on all Salina Code Academy programs and events',
	},
];
