import React from 'react';
import {
	FormControl,
	FormLabel,
	SelectProps,
	FormControlProps,
	FormErrorMessage,
	Select,
} from '@chakra-ui/react';
import { colors } from '../../common/constants';

interface ISelectProps extends SelectProps {
	label: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	options: { value: string; label: string }[];
}

export const CustomSelect = React.forwardRef<null, ISelectProps>(
	({ errorMsg, label, formControlProps, options, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} mb={4} {...formControlProps}>
				<FormLabel
					fontSize="12px"
					fontWeight="600"
					lineHeight="16px"
					mb="8px">
					{label}
				</FormLabel>
				<Select
					placeholder={rest.placeholder}
					ref={ref}
					height="50px"
					borderRadius="8px"
					borderWidth="1px"
					borderColor="neutral.20"
					focusBorderColor="primary.focused"
					_hover={{
						borderColor: 'primary.hovered',
					}}
					_active={{
						borderColor: 'primary.pressed',
					}}
					_focus={{
						boxShadow: `0 0 0 3px ${colors.primary.focused}`,
					}}
					_invalid={{
						borderColor: 'danger.main',
						boxShadow: 'none',
					}}
					_disabled={{
						borderColor: 'neutral.20',
					}}
					_placeholder={{
						color: 'neutral.60',
					}}
					color="neutral.100"
					fontSize="14px"
					fontWeight="400"
					{...rest}>
					{options.map(elem => (
						<option value={elem.value} key={elem.label}>
							{elem.label}
						</option>
					))}
				</Select>
				<FormErrorMessage mt={0}>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
CustomSelect.displayName = 'InputText';
