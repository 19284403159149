import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const jobApplicationSchema = yup.object({
	Email: yup.string().email(ErrorMessages.EMAIL_INVALID),
	Phone: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.test(
			'phone-number',
			ErrorMessages.PHONE_NUMBER_INCOMPLETE,
			function (value?: string) {
				// return value?.replace(/[(\)\-_+]/g, '').length >= 11;
				return !value?.includes('_') || false;
			},
		),
});

export const jobApplicationResolver = yupResolver(jobApplicationSchema);
