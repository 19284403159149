import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Project } from '../../../types';

export const studentProjects: Project[] = [
	{
		name: 'Website',
		age: 13,
		tag: 'Development',
		image: (
			<StaticImage
				alt="Project 1"
				style={{ borderRadius: '8px' }}
				src="../../../images/teens-home/projects1.png"
				layout="fullWidth"
			/>
		),
	},
	{
		name: 'LED lamp with remote control',
		age: 12,
		tag: 'Embedded',
		image: (
			<StaticImage
				alt="Project 2"
				style={{ borderRadius: '8px' }}
				src="../../../images/teens-home/projects2.png"
			/>
		),
	},
	{
		name: 'Website 2',
		age: 10,
		tag: 'Web Design',
		image: (
			<StaticImage
				alt="Project 3"
				style={{ borderRadius: '8px' }}
				src="../../../images/teens-home/projects3.png"
			/>
		),
	},
	{
		name: 'Website 3',
		age: 14,
		tag: 'Web Design',
		image: (
			<StaticImage
				alt="Project 4"
				style={{ borderRadius: '8px' }}
				src="../../../images/teens-home/projects4.png"
			/>
		),
	},
];
