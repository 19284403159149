import React from 'react';
import {
	FormControl,
	FormLabel,
	FormControlProps,
	TextareaProps,
	Textarea,
	FormErrorMessage,
} from '@chakra-ui/react';
import { colors } from '../../common/constants';

interface ICustomTextareaProps extends TextareaProps {
	errorMsg?: string;
	label: string;
	formControlProps?: FormControlProps;
}

export const CustomTextarea = React.forwardRef<null, ICustomTextareaProps>(
	({ errorMsg, label, formControlProps, ...rest }, ref) => {
		return (
			<FormControl isInvalid={!!errorMsg} mb={4} {...formControlProps}>
				<FormLabel
					fontSize="12px"
					fontWeight="600"
					lineHeight="16px"
					mb="8px">
					{label}
				</FormLabel>
				<Textarea
					borderRadius="8px"
					borderWidth="1px"
					borderColor="neutral.20"
					focusBorderColor="primary.focused"
					_hover={{
						borderColor: 'primary.hovered',
					}}
					_active={{
						borderColor: 'primary.pressed',
					}}
					_focus={{
						boxShadow: `0 0 0 3px ${colors.primary.focused}`,
					}}
					_invalid={{
						borderColor: 'danger.main',
						boxShadow: 'none',
					}}
					_disabled={{
						borderColor: 'neutral.20',
					}}
					_placeholder={{
						color: 'neutral.60',
					}}
					color="neutral.100"
					fontSize="14px"
					fontWeight="400"
					{...rest}
					ref={ref}
				/>
				<FormErrorMessage mt={0}>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
CustomTextarea.displayName = 'InputText';
