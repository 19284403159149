import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Testimonial } from '../../../types';

export const studentTestimonials: Testimonial[] = [
	{
		avatar: (
			<StaticImage
				alt="Student testimonial 1"
				src="../../../images/teens-home/testimonial1.png"
			/>
		),
		name: 'Marilyn Siphron',
		age: 17,
	},
	{
		avatar: (
			<StaticImage
				alt="Student testimonial 2"
				src="../../../images/teens-home/testimonial2.png"
			/>
		),
		name: 'Alvin Simon',
		age: 16,
	},
	{
		avatar: (
			<StaticImage
				alt="Student testimonial 3"
				src="../../../images/teens-home/testimonial3.png"
			/>
		),
		name: 'Madelyn Gouse',
		age: 17,
	},
	{
		avatar: (
			<StaticImage
				alt="Student testimonial 1"
				src="../../../images/teens-home/testimonial1.png"
			/>
		),
		name: 'Marilyn Siphron',
		age: 17,
	},
];
