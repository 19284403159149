import React, { useState, useEffect } from 'react';
import {
	FormControl,
	FormLabel,
	InputGroup,
	Input,
	InputProps,
	FormErrorMessage,
	FormControlProps,
	InputLeftElement,
	Select,
	Box,
	Flex,
} from '@chakra-ui/react';
import { colors } from '../../common/constants';
import { getCountryTelCode } from './countries';
import InputMask from 'react-input-mask';
import USflag from '../../assets/flags/us.svg';
import NGFlag from '../../assets/flags/ng.svg';

interface IPhoneInputProps extends InputProps {
	errorMsg?: string;
	label: string;
	formControlProps?: FormControlProps;
	country?: string;
	value: string;
	options: { value: string; label: string }[];
	onChangeValue: (value: { code: string; number: string }) => void;
}

export const InputPhone = React.forwardRef<null, IPhoneInputProps>(
	(
		{
			errorMsg,
			label,
			formControlProps,
			country = 'US',
			onChangeValue,
			value,
			options,
			...rest
		},
		ref,
	) => {
		const [number, setNumber] = useState(value || '');
		const [selectedCountry, setSelectedCountry] = useState(country || '');
		const [countryCode, setCountryCode] = useState(
			getCountryTelCode(country) || '',
		);

		useEffect(() => {
			setSelectedCountry(country);
			setCountryCode(getCountryTelCode(country));
		}, [country]);

		const onCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
			const value = e.target.value;
			const code = getCountryTelCode(value);

			setCountryCode(code);
			setSelectedCountry(value);
			onChangeValue({ code, number });
		};

		const onPhoneNumberChange = (
			e: React.ChangeEvent<HTMLInputElement>,
		) => {
			const value = e.target.value;
			setNumber(value);
			onChangeValue({ code: countryCode, number: value });
		};

		return (
			<FormControl isInvalid={!!errorMsg} mb={4} {...formControlProps}>
				<FormLabel
					fontSize="12px"
					fontWeight="600"
					lineHeight="16px"
					mb="8px">
					{label}
				</FormLabel>
				<InputGroup>
					<InputLeftElement h="100%" w="50px">
						<Flex
							width="100%"
							justifyContent="center"
							alignItems="center">
							<Box width="30px">
								{selectedCountry === 'USA' ? (
									<USflag />
								) : (
									<NGFlag />
								)}
							</Box>
						</Flex>
						<Select
							top="10px"
							left="0"
							icon={<></>}
							zIndex={1}
							bottom={0}
							opacity={0}
							bg="purple"
							height="100%"
							position="absolute"
							value={selectedCountry}
							onChange={onCountryChange}>
							{options.map(option => (
								<option value={option.value} key={option.value}>
									{option.label}
								</option>
							))}
						</Select>
					</InputLeftElement>
					<Input
						height="50px"
						borderRadius="8px"
						pl="50px"
						as={InputMask}
						mask="(999)-999-9999"
						borderWidth="1px"
						borderColor="neutral.20"
						focusBorderColor="primary.focused"
						_hover={{
							borderColor: 'primary.hovered',
						}}
						_active={{
							borderColor: 'primary.pressed',
						}}
						_focus={{
							boxShadow: `0 0 0 3px ${colors.primary.focused}`,
						}}
						_invalid={{
							borderColor: 'danger.main',
							boxShadow: 'none',
						}}
						_disabled={{
							borderColor: 'neutral.20',
						}}
						_placeholder={{
							color: 'neutral.60',
						}}
						color="neutral.100"
						fontSize="14px"
						fontWeight="400"
						onChange={onPhoneNumberChange}
						{...rest}
						ref={ref}
					/>
				</InputGroup>
				<FormErrorMessage mt={0}>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);

InputPhone.displayName = 'InputPhone';
