import React from 'react';
import HardSkillsIllustration from '../../../assets/teens-scratch/hard-skills.svg';
import SoftSkillsIllustration from '../../../assets/teens-scratch/soft-skills2.svg';
import ProjectsIllustration from '../../../assets/teens-scratch/projects.svg';
import { CardWithLargeIllustration } from '../../../types';

export const teensCoursesValues: CardWithLargeIllustration[] = [
	{
		illustration: <HardSkillsIllustration />,
		title: 'Hard Skills',
		description: 'Development of programming skills',
	},
	{
		illustration: <SoftSkillsIllustration />,
		title: 'Soft Skills',
		description: 'Ability to interact in a team with peers',
	},
	{
		illustration: <ProjectsIllustration />,
		title: 'Projects',
		description: 'Presenting and defending your own project',
	},
];
