import React from 'react';
import { Box, Container, Flex, Stack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactsInfo, ContactsForm } from '../../components';

export const ContactsInfoForm: React.FC = () => {
	return (
		<Box bg="primary.surface" position="relative">
			<Box position="absolute" right="0" bottom="0">
				<StaticImage
					src="../../images/pix_waves_green_bottom_right.png"
					alt="decorative waves"
				/>
			</Box>
			<Container
				maxW={{ base: 'container.sm', lg: 'container.lg' }}
				position="inherit"
				zIndex={10}
				pt={{ base: '32px', lg: '110px' }}
				pb="110px">
				<Stack direction={{ base: 'column', lg: 'row' }}>
					<Flex flex={1} direction="column">
						<ContactsInfo />
					</Flex>
					<Box flex={1}>
						<Box
							p={{ base: '24px 16px 32px 16px', md: '40px' }}
							borderRadius="16px"
							bg="neutral.10">
							<ContactsForm />
						</Box>
					</Box>
				</Stack>
			</Container>
		</Box>
	);
};
