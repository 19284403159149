import React from 'react';
import { Text, chakra } from '@chakra-ui/react';
import { WhyChoosePointType } from '../../../types';

export const threeMainBlocks: WhyChoosePointType[] = [
	{
		point: (
			<Text size="L" variant="medium" color="primary.main">
				<chakra.span fontSize="28px" fontWeight="600" lineHeight="40px">
					75%
				</chakra.span>{' '}
				Tech skills
			</Text>
		),
		description:
			'The basic block of knowledge that will help you learn and get your first job in IT',
	},
	{
		point: (
			<Text size="L" variant="medium" color="primary.main">
				<chakra.span fontSize="28px" fontWeight="600" lineHeight="40px">
					15%
				</chakra.span>{' '}
				Soft skills
			</Text>
		),
		description:
			'Teach how to effectively distribute tasks and time, find common ground with the team in any situation',
	},
	{
		point: (
			<Text size="L" variant="medium" color="primary.main">
				<chakra.span fontSize="28px" fontWeight="600" lineHeight="40px">
					10%
				</chakra.span>{' '}
				Career skills
			</Text>
		),
		description:
			'Classes with a career counselor will help you competently write a CV and pass the interview at the IT-company.',
	},
];
