import React from 'react';
import BillIcon from '../../../assets/ic_bill.svg';
import WorkIcon from '../../../assets/ic_work.svg';
import OfficeIcon from '../../../assets/ic_office.svg';
import ModernIcon from '../../../assets/ic_modern.svg';
import { CardWithIcon } from '../../../types';

export const reasons: CardWithIcon[] = [
	{
		icon: <BillIcon />,
		title: 'High salary level',
		description:
			'A beginner Front-End developer can expect to earn from $400, a senior specialist from $1900, and an experienced team leader on average $3500.',
	},
	{
		icon: <WorkIcon />,
		title: 'Opportunity to work remotely',
		description:
			'The IT field implies the possibility of working from anywhere in the world. The flexible schedule and focus on results will allow you to choose the most suitable mode.',
	},
	{
		icon: <OfficeIcon />,
		title: 'Comfortable working conditions',
		description:
			'Comfortable class a offices, recreational areas, sports compensation, health insurance, specialized training, high level of management and project management in teams.',
	},
	{
		icon: <ModernIcon />,
		title: 'Growing industry',
		description:
			'The IT market worldwide is growing. it is the fastest growing industry in the economy (about 8% in 2018). As a consequence, there is a constant shortage of staff in companies and great career prospects.',
	},
];
