import React, { useState } from 'react';
import {
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftElement,
	FormErrorMessage,
	InputProps,
	FormControlProps,
	InputRightElement,
	IconButton,
} from '@chakra-ui/react';
import { colors } from '../../common/constants';
import EyeClosedIcon from '../../assets/EyeClosed.svg';
import EyeOpenIcon from '../../assets/EyeOpen.svg';

interface IInputProps extends InputProps {
	label: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	leftIcon?: JSX.Element;
	rightIcon?: JSX.Element;
	isPassword?: boolean;
}

export const InputText = React.forwardRef<null, IInputProps>(
	(
		{
			errorMsg,
			label,
			leftIcon,
			rightIcon,
			formControlProps,
			isPassword,
			...rest
		},
		ref,
	) => {
		const [showPassword, setShowPassword] = useState(false);
		return (
			<FormControl isInvalid={!!errorMsg} mb={4} {...formControlProps}>
				<FormLabel
					fontSize="12px"
					fontWeight="600"
					lineHeight="16px"
					mb="8px">
					{label}
				</FormLabel>
				<InputGroup>
					{leftIcon ? (
						<InputLeftElement>{leftIcon}</InputLeftElement>
					) : null}
					<Input
						type={
							isPassword
								? showPassword
									? 'text'
									: 'password'
								: rest.type
						}
						height="50px"
						borderRadius="8px"
						borderWidth="1px"
						borderColor="neutral.20"
						focusBorderColor="primary.focused"
						_hover={{
							borderColor: 'primary.hovered',
						}}
						_active={{
							borderColor: 'primary.pressed',
						}}
						_focus={{
							boxShadow: `0 0 0 3px ${colors.primary.focused}`,
						}}
						_invalid={{
							borderColor: 'danger.main',
							boxShadow: 'none',
						}}
						_disabled={{
							borderColor: 'neutral.20',
						}}
						_placeholder={{
							color: 'neutral.60',
						}}
						color="neutral.100"
						fontSize="14px"
						fontWeight="400"
						{...rest}
						ref={ref}
					/>
					{rightIcon ? (
						<InputRightElement height="100%">
							{rightIcon}
						</InputRightElement>
					) : null}
					{isPassword ? (
						<InputRightElement height="100%">
							<IconButton
								aria-label="Show password"
								onClick={() => setShowPassword(!showPassword)}
								variant="unstyled">
								{showPassword ? (
									<EyeClosedIcon />
								) : (
									<EyeOpenIcon />
								)}
							</IconButton>
						</InputRightElement>
					) : null}
				</InputGroup>
				<FormErrorMessage mt={0}>{errorMsg}</FormErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
