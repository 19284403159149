import clsx from 'clsx';
import React, { useMemo, useEffect } from 'react';
import FlipClockDigit from './FlipClockDigit';
import {
	FlipClockCountdownProps,
	FlipClockCountdownRenderProps,
	FlipClockCountdownState,
} from './types';
import { calcTimeDelta, convertToPx, parseTimeDelta } from './utils';

/**
 * A 3D animated flip clock countdown component for React.
 */
const FlipClockCountdown: React.FC<FlipClockCountdownProps> = ({
	to,
	className,
	style,
	children,
	onComplete,
	onTick,
	showLabels = true,
	showSeparators = true,
	labels = ['Days', 'Hours', 'Minutes', 'Seconds'],
	labelStyle,
	digitBlockStyle,
	separatorStyle,
	dividerStyle,
	duration,
	...other
}) => {
	// we don't immediately construct the initial state here because it might
	// lead to some bugs with server-side rendering and hydration.
	const [state, setState] = React.useState<FlipClockCountdownState>();
	const countdownRef = React.useRef(0);
	function clearTimer() {
		window.clearInterval(countdownRef.current);
	}

	function constructState(): FlipClockCountdownState {
		const timeDelta = calcTimeDelta(to);
		return {
			timeDelta,
			completed: timeDelta.total === 0,
		};
	}

	function tick() {
		const newState = constructState();
		setState(newState);
		onTick?.(newState);
		if (newState.completed) {
			clearTimer();
			onComplete?.();
		}
	}

	useEffect(() => {
		setState(constructState());
		clearTimer();
		countdownRef.current = window.setInterval(tick, 1000);

		return () => clearTimer();
	}, [to]);

	const containerStyles = React.useMemo<React.CSSProperties>(() => {
		const s = {
			'--fcc-flip-duration':
				duration === undefined || duration < 0 || duration > 1
					? undefined
					: `${duration}s`,
			'--fcc-digit-block-width': convertToPx(digitBlockStyle?.width),
			'--fcc-digit-block-height': convertToPx(digitBlockStyle?.height),
			'--fcc-shadow': digitBlockStyle?.boxShadow,
			'--fcc-digit-font-size': convertToPx(digitBlockStyle?.fontSize),
			'--fcc-digit-color': digitBlockStyle?.color,
			'--fcc-label-font-size': convertToPx(labelStyle?.fontSize),
			'--fcc-label-color': labelStyle?.color,
			'--fcc-divider-color': dividerStyle?.color,
			'--fcc-divider-height': convertToPx(dividerStyle?.height),
			'--fcc-background':
				digitBlockStyle?.background || digitBlockStyle?.backgroundColor,
			'--fcc-separator-size': convertToPx(separatorStyle?.size),
			'--fcc-separator-color': showSeparators
				? separatorStyle?.color
				: 'transparent',
			...style,
		};

		return s;
	}, [
		style,
		digitBlockStyle,
		labelStyle,
		duration,
		dividerStyle,
		separatorStyle,
		showSeparators,
	]);

	const _digitBlockStyle = useMemo(() => {
		if (digitBlockStyle) {
			return {
				...digitBlockStyle,
				background: undefined,
				backgroundColor: undefined,
				width: undefined,
				height: undefined,
				boxShadow: undefined,
				fontSize: undefined,
				color: undefined,
			};
		}
		return undefined;
	}, [digitBlockStyle]);

	const renderProps = React.useMemo<
		FlipClockCountdownRenderProps | undefined
	>(() => {
		if (state === undefined) {
			return undefined;
		}
		const { timeDelta } = state;
		return {
			...timeDelta,
			formatted: parseTimeDelta(timeDelta),
		};
	}, [state]);

	if (state === undefined || renderProps === undefined)
		return <React.Fragment></React.Fragment>;

	if (state?.completed) {
		return <React.Fragment>{children}</React.Fragment>;
	}

	const { weeks, days, hours, minutes, seconds } = renderProps.formatted;
	const _labels =
		labels.length >= 4 ? labels : ['Weeks', 'Days', 'Hours', 'Minutes'];

	return (
		<div
			{...other}
			className={clsx(
				'fcc__container',
				{
					['fcc__label_show']: showLabels,
				},
				className,
			)}
			style={containerStyles}
			data-testid="fcc-container">
			{[weeks, days, hours, minutes].map((item, idx) => {
				return (
					<React.Fragment key={`digit-block-${idx}`}>
						<div className="fcc__digit_block_container">
							{showLabels && (
								<div
									className="fcc__digit_block_label"
									style={labelStyle}>
									{_labels[idx]}
								</div>
							)}
							{item.current.map((cItem, cIdx) => (
								<FlipClockDigit
									key={cIdx}
									current={cItem}
									next={item.next[cIdx]}
									style={_digitBlockStyle}
								/>
							))}
						</div>
						{idx < 3 && <div className="fcc__colon"></div>}
					</React.Fragment>
				);
			})}
		</div>
	);
};

export default FlipClockCountdown;
