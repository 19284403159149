import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Teacher } from '../../../types';

export const teachers: Teacher[] = [
	{
		name: 'Aubrey Collier',
		description:
			'Lorem ipsum dolor sit amet, insectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		image: (
			<StaticImage
				alt="Aubrey Collier teacher"
				src="../../../images/teens-about/teacher1.png"
			/>
		),
		role: 'Front-End Developer',
	},
	{
		name: 'Margaret Kris Sr.',
		description:
			'Lorem ipsum dolor sit amet, insectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		image: (
			<StaticImage
				alt="Margaret Kris Sr. teacher"
				src="../../../images/teens-about/teacher2.png"
			/>
		),
		role: 'Soft Skills Trainer',
	},
	{
		name: 'Willis Hudson',
		description:
			'Lorem ipsum dolor sit amet, insectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		image: (
			<StaticImage
				alt="Willis Hudson teacher"
				src="../../../images/teens-about/teacher3.png"
			/>
		),
		role: 'Game Developer',
	},
];
