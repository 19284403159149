import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';
import dayjs from 'dayjs';

const teensSignupSchema = yup.object({
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	childDateOfBirth: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.test('over-year', 'You are too young', function (value?: string) {
			const difference = dayjs(new Date()).diff(value);
			return dayjs.duration(difference).asYears() >= 1;
		}),
	email: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID),
	phoneNumber: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.test(
			'phone-number',
			ErrorMessages.PHONE_NUMBER_INCOMPLETE,
			function (value?: string) {
				return !value?.includes('_') || false;
			},
		),
	childFirstName: yup.string().required(ErrorMessages.REQUIRED),
	childLastName: yup.string().required(ErrorMessages.REQUIRED),
	childEmail: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID)
		.test(
			'different-emails',
			ErrorMessages.EMAIL_DIFFERENT,
			function (value) {
				const { email } = this.parent;
				return email !== value;
			},
		),
	password: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.matches(/\d/, ErrorMessages.PASSWORD_DIGIT)
		.matches(/[A-Z]/, ErrorMessages.PASSWORD_UPPERCASE)
		.test(
			'unique-chars',
			ErrorMessages.MINIMUM_4_CHARS,
			function (value?: string) {
				return new Set(value?.split('')).size >= 4;
			},
		),
	confirmPassword: yup
		.string()
		.min(6, ErrorMessages.SHORT_PASSWORD)
		.required(ErrorMessages.REQUIRED)
		.test('password-match', ErrorMessages.PASSWORD_MATCH, function (value) {
			const { password } = this.parent;
			return password === value;
		}),
});

export const teensSignupResolver = yupResolver(teensSignupSchema);
