import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import { CommonContainer } from '../../components';

interface IHeaderProps {
	onScrollToContactsSection: () => void;
}

export const Header: React.FC<IHeaderProps> = ({
	onScrollToContactsSection,
}) => {
	return (
		<Box bg="secondary.surface">
			<CommonContainer maxW="container.lg">
				<Flex justify="space-between" height="92px" align="center">
					<StaticImage
						src="../../images/Logo.png"
						alt="Header logo"
					/>
					<Button
						variant="secondary"
						display={{ base: 'none', md: 'block' }}
						onClick={onScrollToContactsSection}>
						Contact Us
					</Button>
				</Flex>
			</CommonContainer>
		</Box>
	);
};
