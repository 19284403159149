import React from 'react';
import { Center, HStack, Link, Text, Flex } from '@chakra-ui/react';
import { footer_social_links } from '../../common/constants';
import { CommonContainer } from '../../components';

export const Footer: React.FC = () => {
	return (
		<Flex direction="column" justify="center" h="170px">
			<CommonContainer>
				<Center>
					<HStack spacing="48px" mb="32px">
						{footer_social_links.map(elem => (
							<Link key={elem.url} isExternal href={elem.url}>
								{elem.icon}
							</Link>
						))}
					</HStack>
				</Center>
				<Text
					size="L"
					variant="regular"
					textAlign="center"
					color="neutral.70">
					© Copyright 2022 – Salina Code Academy, LLC – All Rights
					Reserved
				</Text>
			</CommonContainer>
		</Flex>
	);
};
