import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from './messages';

const contactsFormSchema = yup.object({
	firstName: yup.string().required(ErrorMessages.REQUIRED),
	lastName: yup.string().required(ErrorMessages.REQUIRED),
	phone: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.test(
			'phone-number',
			ErrorMessages.PHONE_NUMBER_INCOMPLETE,
			function (value?: string) {
				return !value?.includes('_') || false;
			},
		),
	email: yup
		.string()
		.required(ErrorMessages.REQUIRED)
		.email(ErrorMessages.EMAIL_INVALID),
});

export const contactsFormResolver = yupResolver(contactsFormSchema);
