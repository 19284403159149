import React from 'react';
import MedalIcon from '../../../assets/ic_medal.svg';
import SpeakIcon from '../../../assets/ic_speak2.svg';
import WorkIcon from '../../../assets/ic_work.svg';
import LaptopIcon from '../../../assets/ic_solid-laptop.svg';
import HandshakeIcon from '../../../assets/ic_handshake.svg';
import RocketIcon from '../../../assets/ic_rocket2.svg';
import { CardWithIcon } from '../../../types';

export const courseIsPoints: CardWithIcon[] = [
	{
		icon: <MedalIcon />,
		title: 'A program whose quality is confirmed by the best professionals and employers',
	},
	{
		icon: <SpeakIcon />,
		title: 'Personal mentor support and direct communication with faculty',
	},
	{
		icon: <WorkIcon />,
		title: 'Assistance in employment and career development',
	},
	{
		icon: <LaptopIcon />,
		title: 'Convenient and efficient online format',
	},
	{
		icon: <HandshakeIcon />,
		title: 'Post-graduation support and even more growth opportunities',
	},
	{
		icon: <RocketIcon />,
		title: 'Iron guarantees',
	},
];
