export enum Audience {
	Adults,
	Teens,
}

export type CardWithLargeIllustration = {
	illustration: JSX.Element;
	title: string;
	description?: JSX.Element | string;
};

export type CardWithIcon = {
	icon: JSX.Element;
	title: string;
	description?: string;
	modulesAmount?: string;
};

export type Testimonial = {
	avatar: JSX.Element;
	name: string;
	age: number;
};

export type SkillDirectory = { id: number; name: string };

export type Teacher = {
	name: string;
	image: JSX.Element;
	role: string;
	description: string;
};

export type Project = {
	name: string;
	age: number;
	tag: string;
	image: JSX.Element;
};

export type Course = {
	id: number;
	name: string;
	image: {
		itemHash: string;
		extension: string;
	} | null;
	price: number;
};

export type ContactUsForm = {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	phoneCode?: string;
};

export type WhyChoosePointType = {
	point: JSX.Element;
	description: string;
};

export type PossibleItemType = {
	image: JSX.Element;
	title: string;
	description: string;
};
