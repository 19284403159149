import React from 'react';
import { Heading, Text, chakra } from '@chakra-ui/react';
import { WhyChoosePointType } from '../../../types';

export const whyChoosePoints: WhyChoosePointType[] = [
	{
		point: (
			<Text size="L" variant="medium" color="primary.main">
				<chakra.span fontSize="28px" fontWeight="600" lineHeight="40px">
					3+
				</chakra.span>{' '}
				years
			</Text>
		),
		description: 'On the US market',
	},
	{
		point: (
			<Text size="L" variant="medium" color="primary.main">
				<chakra.span fontSize="28px" fontWeight="600" lineHeight="40px">
					10 000+
				</chakra.span>{' '}
				students
			</Text>
		),
		description: 'From all over the country',
	},
	{
		point: (
			<Heading size="S" color="primary.main">
				Development
			</Heading>
		),
		description: 'That prepares the child for the future',
	},
	{
		point: (
			<Heading size="S" color="primary.main">
				Quality control
			</Heading>
		),
		description: 'Communicating openly with parents',
	},
];
