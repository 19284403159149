import React from 'react';
import {
	Box,
	Flex,
	Heading,
	Stack,
	Text,
	useBreakpointValue,
} from '@chakra-ui/react';
import { CommonContainer } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';

export const StayTuned: React.FC = () => {
	const imageTransformTablet = useBreakpointValue({
		md: { height: '300px', borderRadius: '16px' },
		lg: undefined,
	});
	return (
		<Box>
			<CommonContainer py={{ base: '80px', lg: '120px' }}>
				<Stack direction={{ base: 'column', md: 'row' }} spacing="56px">
					<Box flex={{ md: 0.4, lg: 1 }}>
						<StaticImage
							alt="Developer in work"
							src="../../images/countdown/stay-tuned.png"
							style={imageTransformTablet}
						/>
					</Box>
					<Flex
						direction="column"
						justify="center"
						flex={{ md: 0.6, lg: 1 }}>
						<Heading
							size={{ base: 'S', lg: 'M' }}
							color="neutral.100"
							mb="16px">
							Stay tuned!
						</Heading>
						<Text
							size="L"
							variant="regular"
							color="neutral.70"
							mb="8px">
							Our pilot program was very successful and we are
							going back to the drawing board to use what we
							learnt to restructure better for the kids and youth
							in our community that we will be serving.
						</Text>
						<Text size="L" variant="medium" color="neutral.70">
							We will make our platform more convenient, easier
							and more productive.
						</Text>
					</Flex>
				</Stack>
			</CommonContainer>
		</Box>
	);
};
