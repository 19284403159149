import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';

interface ICommonContainerProps extends ContainerProps {
	children?: React.ReactNode;
}

export const CommonContainer: React.FC<ICommonContainerProps> = ({
	children,
	maxW = 'container.lg',
	...rest
}) => {
	return (
		<Container
			maxW={maxW}
			px={{ base: '1rem', md: '2rem', lg: '1rem' }}
			{...rest}>
			{children}
		</Container>
	);
};
