import React from 'react';
import { Button } from '@chakra-ui/react';
import { InputText } from '../InputText/InputText';
import { InputPhone } from '../InputPhone/InputPhone';
import { useForm, Controller } from 'react-hook-form';
import { contactsFormResolver } from '../../common/validation';
import { countries } from '../../common/constants';
import { ContactUsForm } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { sendContactRequest } from '../../redux/reducers/contacts.reducer';
import { RootStore } from '../../redux';

export const ContactsForm: React.FC = () => {
	const dispatch = useDispatch();
	const loading = useSelector((state: RootStore) => state.contacts.loading);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		setValue,
	} = useForm<ContactUsForm>({
		resolver: contactsFormResolver,
	});

	const onSubmit = (data: ContactUsForm) => {
		dispatch(
			sendContactRequest({
				...data,
				phone: `${data.phoneCode}${data.phone}`,
				phoneCode: undefined,
			}),
		);
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<InputText
				label="First Name"
				{...register('firstName')}
				errorMsg={errors.firstName?.message}
			/>
			<InputText
				label="Last Name"
				{...register('lastName')}
				errorMsg={errors.lastName?.message}
			/>
			<InputText
				label="Email"
				type="email"
				{...register('email')}
				errorMsg={errors.email?.message}
			/>
			<Controller
				name="phone"
				render={({ field }) => (
					<InputPhone
						label="Phone"
						value={field.value}
						options={countries}
						placeholder="(___)-___-____"
						country="USA"
						errorMsg={errors.phone?.message}
						onChangeValue={value => {
							setValue('phoneCode', value.code);
							field.onChange(value.number);
						}}
					/>
				)}
				control={control}
			/>

			<Button
				variant="primary"
				mt="16px"
				w={{ base: '100%', md: '160px' }}
				type="submit"
				isLoading={loading}>
				Send
			</Button>
		</form>
	);
};
