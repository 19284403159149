import React from 'react';
import { Box } from '@chakra-ui/react';

interface IListBulletProps {
	color?: string;
}

export const ListBullet: React.FC<IListBulletProps> = ({
	color = '#F6CC37',
}) => <Box w="6px" h="6px" borderRadius="3px" flexShrink={0} bg={color} />;
