import React from 'react';
import { Box, Center, Flex, Text, VStack } from '@chakra-ui/react';
import CheckmarkBulletIcon from '../../assets/checkmark_bullet.svg';

interface IProgressStepsProps {
	steps: string[];
	activeStep: number;
}

const CompletedStepPoint: React.FC = () => (
	<Center
		bg="primary.main"
		h="21px"
		w="21px"
		borderRadius="10px"
		px="5px"
		color="white">
		<CheckmarkBulletIcon />
	</Center>
);

const CurrentStepPoint: React.FC = () => (
	<Center
		h="21px"
		w="21px"
		borderRadius="10px"
		borderWidth="1px"
		bg="neutral.10"
		borderColor="primary.main">
		<Box h="10px" w="10px" borderRadius="5px" bg="primary.main" />
	</Center>
);

const InactiveStepPoint: React.FC = () => (
	<Box
		h="21px"
		w="21px"
		bg="neutral.10"
		borderRadius="10px"
		borderWidth="1px"
		borderColor="neutral.30"
	/>
);

export const ProgressSteps: React.FC<IProgressStepsProps> = ({
	steps,
	activeStep,
}) => {
	const getPointState = (index: number, title: string) => {
		if (index === activeStep) {
			return (
				<VStack>
					<CurrentStepPoint />
					<Text
						size={{ base: 'S', md: 'L' }}
						color="primary.main"
						variant="medium">
						{title}
					</Text>
				</VStack>
			);
		}
		if (index < activeStep) {
			return (
				<VStack>
					<CompletedStepPoint />
					<Text
						size={{ base: 'S', md: 'L' }}
						color="primary.main"
						variant="medium">
						{title}
					</Text>
				</VStack>
			);
		}
		if (index > activeStep) {
			return (
				<VStack>
					<InactiveStepPoint />
					<Text
						size={{ base: 'S', md: 'L' }}
						color="neutral.50"
						variant="medium">
						{title}
					</Text>
				</VStack>
			);
		}
		return null;
	};

	return (
		<Box position="relative" mb={{ base: '20px', md: '40px' }}>
			<Box px="4rem" mb="50px">
				<Box
					borderBottom="1px dashed"
					borderBottomColor="neutral.30"
					width="100%"
				/>
			</Box>
			<Flex
				w="100%"
				justify="space-between"
				px="1.5rem"
				position="absolute"
				top="-10px">
				{steps.map((elem, index) => getPointState(index, elem))}
			</Flex>
		</Box>
	);
};
