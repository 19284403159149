import React from 'react';
import TeacherIcon from '../../../assets/ic_teacher.svg';
import LaptopIcon from '../../../assets/ic_laptop-code.svg';
import HomeWorkIcon from '../../../assets/ic_home-work.svg';
import { CardWithIcon } from '../../../types';

export const trialAdvantages: CardWithIcon[] = [
	{
		icon: <TeacherIcon />,
		title: 'Get to know the teacher and the Scratch program',
	},
	{
		icon: <LaptopIcon />,
		title: 'Write her first block code and create animations',
	},
	{
		icon: <HomeWorkIcon />,
		title: 'Decides if he wants to do it further',
	},
];
