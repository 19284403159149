export enum ErrorMessages {
	REQUIRED = 'This is a required field',
	EMAIL_INVALID = 'Email is invalid',
	EMAIL_DIFFERENT = 'Emails should be different',
	PHONE_NUMBER_INCOMPLETE = 'Please enter full number',
	SHORT_PASSWORD = 'Password should be minimum 6 characters long',
	PASSWORD_DIGIT = 'Password should have at least one number',
	PASSWORD_UPPERCASE = 'Password should have at least one uppercase character',
	PASSWORD_MATCH = 'Passwords do not match',
	MINIMUM_4_CHARS = 'Password should have at least 4 unique characters',
}
