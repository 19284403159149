import React from 'react';
import { Box, Heading, Text, HStack, Link } from '@chakra-ui/react';
import CallIcon from '../../assets/call.svg';
import SmsIcon from '../../assets/sms.svg';
import LocationIcon from '../../assets/location.svg';
import { footer_social_links } from '../../common/constants';

export const ContactsInfo: React.FC = () => {
	return (
		<>
			<Heading size={{ base: 'M', md: 'L' }} color="neutral.100">
				How to contact us?
			</Heading>
			<Text
				size={{ base: 'M', md: 'L' }}
				color="neutral.70"
				variant="regular"
				mt="13px">
				Fill Up the form and our Team will get back to you within 24
				hours
			</Text>
			<HStack as={Link} href="tel:17858332473" mb="32px" mt="56px">
				<CallIcon />
				<Text
					size={{ base: 'M', md: 'L' }}
					color="neutral.100"
					variant="regular">
					(785) 833-2473
				</Text>
			</HStack>
			<HStack
				as={Link}
				href="mailto:support@salinacodeacademy.com"
				mb="32px">
				<SmsIcon />
				<Text
					size={{ base: 'M', md: 'L' }}
					color="neutral.100"
					variant="regular">
					support@salinacodeacademy.com
				</Text>
			</HStack>
			<HStack>
				<LocationIcon />
				<Text
					size={{ base: 'M', md: 'L' }}
					color="neutral.100"
					variant="regular">
					203 W Ash Street, Salina, KS 67401
				</Text>
			</HStack>
			<Box mt={{ base: '32px', lg: 'auto' }} mb={{ base: '62px', lg: 0 }}>
				<Text
					size={{ base: 'M', md: 'L' }}
					variant="medium"
					color="primary.main"
					mb="16px">
					Follow us on socials
				</Text>
				<HStack spacing="40px">
					{footer_social_links.map(elem => (
						<Link key={elem.url} isExternal href={elem.url}>
							{elem.icon}
						</Link>
					))}
				</HStack>
			</Box>
		</>
	);
};
