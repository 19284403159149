import React from 'react';
import { Box, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { CommonContainer } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import FlipClockCountdown from '../../components/FlipCountdown';

export const Banner: React.FC = () => {
	return (
		<Box bg="secondary.surface">
			<CommonContainer pt="40px" pb={{ base: '60px', md: '40px' }}>
				<Stack
					direction={{ base: 'column', md: 'row' }}
					spacing={{ base: '60px', lg: '100px' }}>
					<Flex
						direction="column"
						justify="center"
						flex={{ base: 1, md: 0.6, lg: 1 }}>
						<Heading
							size={{ base: 'M', lg: 'L' }}
							color="neutral.100"
							textTransform="uppercase">
							SCA will be re-opened in august with renewed courses
							and after school programs
						</Heading>
						<Text
							size={{ base: 'M', lg: 'L' }}
							variant="regular"
							color="neutral.70"
							mt="24px"
							mb="40px">
							No classes this semester and will be back in August
							with more revamped courses.
						</Text>
						<FlipClockCountdown
							// August, 15 2023
							to={new Date(2023, 7, 15)}
							labels={['Weeks', 'Days', 'Hours', 'Minutes']}>
							<Text
								color="neutral.100"
								variant="regular"
								size="L">
								Please stand by
							</Text>
						</FlipClockCountdown>
					</Flex>
					<Box flex={{ base: 1, md: 0.4, lg: 1 }}>
						<StaticImage
							src="../../images/countdown/banner.png"
							alt="Happy girl"
						/>
					</Box>
				</Stack>
			</CommonContainer>
		</Box>
	);
};
