import React, { useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { Banner, Header, Footer, StayTuned } from '../page-sections/Countdown';
import { ContactsInfoForm } from '../page-sections/TeensContacts';
import { SEO } from '../components';

const AdultsIndexPage: React.FC = () => {
	const contactSectionRef = useRef<HTMLDivElement | null>(null);

	const onScrollToContactsSection = () => {
		contactSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
	};

	// const dispatch = useDispatch();
	// const [searchText, setSearchText] = useQueryParamString(
	// 	'showRegistration',
	// 	'',
	// );
	//
	// useEffect(() => {
	// 	if (searchText === 'true') {
	// 		dispatch(toggleAdultsSignupModal(true));
	// 		setSearchText('');
	// 	}
	// }, [searchText]);

	return (
		<>
			<SEO
				title="Salina Code Academy"
				description="We'll be back in January with renewed courses"
				keywords={[
					'Online learning',
					'Programming courses',
					'Programming courses for children',
					'Python',
					'Scratch',
					'Minecraft',
					'Web design',
					'Project management',
					'React Native',
					'Flutter',
				]}
			/>
			{/* <Helmet>
				<title>Salina Code Academy</title>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
			</Helmet> */}
			<Header onScrollToContactsSection={onScrollToContactsSection} />
			<Banner />
			<StayTuned />
			<Box ref={contactSectionRef}>
				<ContactsInfoForm />
			</Box>
			<Footer />
		</>
	);
};

export default AdultsIndexPage;
